/** @jsx jsx */
import * as React from "react"
import { jsx, Container, Heading } from "theme-ui"
import { TheLayout } from "../components/TheLayout"
import CountUp from 'react-countup'

// markup
const IndexPage = () => {
  return (
    <>
    <TheLayout centered>
    <Container sx={{ mb: "var(--heading-height)"}}>

      <title>Home Page</title>
      <Heading as="h4" sx={{ fontWeight: 400, fontSize: [3, 4, 5], marginBottom: 2 }}>Not to take too much credit, but…</Heading>
		 
    <CountUp
  start={19}
  end={1905832867}
  delay={0}
  duration={5.75}
  separator=","
  decimals={0}
  decimal="."
  prefix=""
  suffix=""
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
>
  {({ countUpRef }) => (
    <Heading variant="display" sx={{ fontWeight: 600,fontSize: [5,6,7]}}>Without us, the internet would only have <span ref={countUpRef} /> sites.</Heading>
    
  )}
</CountUp>
    
    </Container>
    </TheLayout>
    </>
  )
}

export default IndexPage
